import React from 'react'
import { NavLink } from "react-router-dom";
import "./UserEmail.css";




const UserEmail2 = (props) => {


	const header = {
		fontSize: "40px",
		fontFamily: "avenir",
		color: "#ED7F61",
	}

	const subheader = {
		fontSize: "18px",
		fontFamily: "avenir",
		color: "gray",
	}

	const input = {
		width: "90%",
		padding: "10px",
		boxSizing: "border-box",
		border: "solid #ED7F61 2px",
		borderRadius: "5px",
		marginBottom: "20px"
	}

	const submit = {
		width: "90%",
		padding: "10px",
		boxSizing: "border-box",
		backgroundColor: "#ED7F61",
		borderRadius: "5px",
		border: "transparent",
		fontFamily: "avenir",
		color: "#FFFFFF",
		fontSize: "18px",
		marginBottom: "10px",
		marginTop: "1rem"
	}


	const itemheader = {
		fontSize: "22px",
		fontFamily: "avenir",
		color: "#ED7F61",
		float: "left",
		marginLeft: "1.5rem",
		marginBottom: ".5rem"
	}



	return (
		<>
			<div className="body">
				<div className="box">
					<div className='content'>
						<h2 style={header}>Log in</h2>
						<p style={subheader}>Welcome back to Astrapilot!</p>
						<form onSubmit={props.emailSubmit}>
							<span style={itemheader}>Email</span>
							<input onChange={props.enterEmail} style={input} type='email' value={props.userEmail}></input>
							<button style={submit}>{props.type}</button>
						</form>
						<>{(props.type === "sign up") ? <><span style={{ fontFamily: "avenir", fontSize: "18px" }}>Already have an account?
							<NavLink to="/login">login</NavLink></span></> : <><span style={{ fontFamily: "avenir", fontSize: "18px" }}>Don't have an account? <NavLink to="/signup">Sign Up</NavLink></span></>}</>
					</div>
				</div>
			</div>
		</>
	)
}

export default UserEmail2
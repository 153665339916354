import { NavLink } from "react-router-dom";
import "./UserEmail.css";



const UserEmail = (props) => {

	const header = {
		fontSize: "40px",
		fontFamily: "avenir",
		color: "#ED7F61",
	}

	const itemheader = {
		fontSize: "22px",
		fontFamily: "avenir",
		color: "#ED7F61",
		float: "left",
		marginLeft: "1.5rem",
		marginBottom: ".5rem"
	}

	const subheader = {
		fontSize: "18px",
		fontFamily: "avenir",
		color: "gray",
	}

	const input = {
		width: "90%",
		padding: "10px",
		boxSizing: "border-box",
		border: "solid #ED7F61 2px",
		borderRadius: "5px",
		marginBottom: "20px"
	}

	const submit = {
		width: "90%",
		padding: "10px",
		boxSizing: "border-box",
		backgroundColor: "#ED7F61",
		borderRadius: "5px",
		border: "transparent",
		fontFamily: "avenir",
		color: "#FFFFFF",
		fontSize: "18px",
		marginBottom: "10px",
		marginTop: "1rem"
	}


	return (
		<>
			<div className="body">
				<div className="box">
					<div className="content">
						<h2 style={header}>Create your account</h2>
						<p style={subheader}>Let’s start embarking on your child’s educational storytelling adventure.</p>
						<form onSubmit={props.emailSubmit}>
							<span style={itemheader}>First name</span>
							<input style={input} onChange={props.enterFirstName} value={props.firstName} required></input>
							<span style={itemheader}>Last name</span>
							<input style={input} onChange={props.enterLastName} value={props.lastName} required></input>
							<span style={itemheader}>Email</span>
							<input style={input} onChange={props.enterEmail} type='email' value={props.userEmail} required></input>
							<span style={{ display: "block" }}> <input type="checkbox" required /><span style={{fontSize: "18px"}}> I agree to the <NavLink to="/terms">Terms and Service</NavLink></span></span>
							<button style={submit}>{props.type}</button>
						</form>
						<>{(props.type === "Sign up") ? <><span style={{ fontFamily: "avenir", fontSize: "18px" }}>Already have an account? <NavLink to="/login">Login</NavLink></span></> : <><span style={{ fontFamily: "avenir", fontSize: "18px" }}>Don't have an account? <NavLink to="/signup">Sign Up</NavLink></span></>}</>
					</div>
				</div>
			</div>
		</>

	)
}

export default UserEmail
//import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ChatPage from './pages/ChatPage';
//import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Navbar from "./app/components/Navbar";
import Enter from './app/components/Enter'
import { useState, useEffect } from "react";
import UserEmail from './app/components/UserEmail'
import UserEmail2 from './app/components/UserEmail2'

import axios from "axios";
import { NavLink } from 'react-router-dom';
import { HashLink} from 'react-router-hash-link';
import Footer from './app/components/Footer';



const URL =  process.env.REACT_APP_SERVER_URL;

function App() {


  let [loggedIn, setLoggedIn]=useState(false)
  let [userEmail,setUserEmail]=useState('')
  let [firstName,setFirstName]=useState('')
  let [lastName,setLastName]=useState('')



// Dealing with the token
const token = JSON.parse(localStorage.getItem("token"));
const fn = localStorage.getItem("firstname");
const ln = localStorage.getItem("lastname");
const email = localStorage.getItem("email");





useEffect(() => {
  const verify_token = async () => {
    if (token === null) return setLoggedIn(false);
    try {
      axios.defaults.headers.common["Authorization"] = token;
      const response = await axios.post(`${URL}/users/verify`)
      return response.data.ok ? 
      login(token) : logout();
    } catch (error) {
      console.log(error);
    }
  };
  verify_token();
}, []);

// Sign in, log in, log out
const login = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
  setLoggedIn(true);
  //console.log(`this is name of user ${activeFirstName}`)
};
const logout = () => {
  localStorage.removeItem("token");
  setLoggedIn(false);
  localStorage.removeItem("firstname");
  localStorage.removeItem("lastname");
  localStorage.removeItem("email");


};

const delete_user = async (email) => {
 
    await axios.delete(`${URL}/users/delete/:id`,{ params: { id: email } })
    .then(res => {
      alert("successfully deleted account"); 
      logout();
    })
    .catch( error => console.log(error) );
    }


const signIn = async (email,magicLink) => {
  try{
    let res = await axios.post(`${URL}/users/enter`,{email,magicLink})
    if(res.data.token){
      alert(res.data.message); 
      login(res.data.token);
      localStorage.setItem("firstname", res.data.firstname);
      localStorage.setItem("lastname", res.data.lastname);
      localStorage.setItem("email", res.data.email)

    }else{ 
      alert(res.data.message)
    }
  }catch(e){
    alert(e)
  }
}
// ---
const signUp = async (email,firstname, lastname) => {
  try{
    let res = await axios.post(`${URL}/users/create`,{email,firstname, lastname})
    if(res.data.token){
      alert(res.data.message) 
      login(res.data.token)
    }else{ 
      alert(res.data.message)
    }
  }catch(e){
    alert(e)
  }
}

// Event listeners 
const enterEmail = (e) => {
  setUserEmail(e.target.value)
}
const enterFirstName = (e) => {
  setFirstName(e.target.value)
}

const enterLastName = (e) => {
  setLastName(e.target.value)
}


const emailSubmit=(e)=>{
  e.preventDefault()
  signIn(userEmail)
  setUserEmail('')
}

const emailSubmit2=(e)=>{
  e.preventDefault()
  signUp(userEmail, firstName, lastName)
  setUserEmail('')
  setFirstName('')
  setLastName('')
}
// ---


  return (
    <>  
    <BrowserRouter>
    <Navbar isLoggedIn={loggedIn} logout={logout} delete_user={delete_user}/>
    <Routes>
    <Route path='/' element={<>{!loggedIn ? <Home/> : <><ChatPage loggedIn={loggedIn} firstName={fn} lastName={ln} email={email}/></>}</>} />
    <Route path='/privacy' element={<><div>Privacy</div></>} />
    <Route path='/terms' element={<Terms/>} />
    <Route path='/signup' element={ <><UserEmail 
  enterEmail={enterEmail} 
  enterFirstName={enterFirstName} 
  enterLastName={enterLastName} 
  emailSubmit={emailSubmit2} 
  userEmail={userEmail} 
  firstName={firstName} 
  lastName={lastName} 
  setUserEmail={setUserEmail} setFirstName={setFirstName} setLastName={setLastName} type="Sign up"/></>}/>
    <Route path='/login' element={ <><UserEmail2 
  enterEmail={enterEmail} 
  emailSubmit={emailSubmit} 
  userEmail={userEmail} 
  setUserEmail={setUserEmail} 
  type="Sign in"/></>}/>
    <Route
  path="enter/:email/:link"
  element={<Enter signIn={signIn} />}
  />
    </Routes>
    <Footer/>
  </BrowserRouter>
  </>

  );
}

export default App;

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import * as React from 'react';
import { NavLink } from "react-router-dom";
import whitelogo from "../../images/whiteLogo.svg";
import Divider from '@mui/material/Divider';
import twitter from "../../images/twitter.svg";
import instagram from "../../images/instagram.svg";
import linkedin from "../../images/linkedin.svg";
import facebook from "../../images/facebook.svg";
import tiktok from "../../images/tiktok.svg";
import "./Footer.css";
import { useState, useCallback, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';


const useMediaQuery = (width) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
        if (e.matches) {
            setTargetReached(true);
        } else {
            setTargetReached(false);
        }
    }, []);

    useEffect(() => {
        const media = window.matchMedia(`(max-width: ${width}px)`);
        media.addEventListener("change", updateTarget)

        // Check on mount (callback is not called until a change occurs)
        if (media.matches) {
            setTargetReached(true);
        }

        return () => media.removeEventListener("change", updateTarget);


    }, []);

    return targetReached;
};







const Footer = () => {

    const isBreakpoint = useMediaQuery(480)


    const body = {

        backgroundColor: "#ED7F61",
        height: "30rem",
        width: "100%",

    }

    const column = {
        display: "flex",
        flexDirection: "column",
        color: "#FFFFFF"

    }





    const subheader = {
        width: "13rem",
        color: "white",
        fontFamily: "avenir",
        fontSize: "20px",
        lineHeight: "1.5"
    }



    const content = {
        marginLeft: "5%",
        marginRight: "5%",
        paddingTop: "5rem"
    }

    const item = {
        fontSize: "18px",
        paddingBottom: "3px",
        color: "#FFFFFF"
    }




    return (
        <>
            {!isBreakpoint ? (
                <div style={body}>
                    <div style={content}>
                        <Grid container spacing={4}>
                            <Grid xs={5} sm={6}>
                                <div style={column}>
                                    <img src={whitelogo} className="whitelogostyle" alt="white logo" />
                                    <p style={subheader}>Meet the future of early education</p>

                                </div>
                            </Grid>
                            <Grid xs={3} sm={2}>
                                <div style={column}>
                                    <h2>Product</h2>
                                    <HashLink smooth to='/#how-it-works'><div style={item}> How It Works</div></HashLink>
                                    <NavLink to="/login"><div style={item}>Log In</div></NavLink>
                                    <NavLink to="/signup"><div style={item}>Sign Up</div></NavLink>
                                </div>
                            </Grid>
                            <Grid xs={3} sm={2}>
                                <div style={column}>
                                    <h2>Company</h2>
                                    <HashLink smooth to='/#ourstory'><div style={item}> Our Story</div></HashLink>
                                    <a href='https://astrapilot.notion.site/astrapilot/Astrapilot-Careers-dafb51c330ee437a90cd8542a60150a0'><div style={item}>Career</div></a>
                                     <a href='https://astrapilot.notion.site/astrapilot/Astrapilot-FAQ-e4be762faac2407ea1c524520a4bf3d2'><div style={item}>FAQ</div></a>
                                </div>
                            </Grid>
                            <Grid xs={1} sm={2}>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                <div className="socials">
                                    <a href='https://twitter.com/astrapilot'> <img src={twitter} width={30} height={30} alt='twitter logo' /></a>
                                    <a href='https://www.instagram.com/astrapilothq/'><img src={instagram} width={30} height={30} alt='instagram logo' /></a>
                                    <a href='https://www.tiktok.com/@astrapilot'><img src={tiktok} width={30} height={30} alt='tiktok logo' /></a>
                                     
                                </div>
                                <div className="socials">

                                <a href='https://www.linkedin.com/company/astrapilot/'><img src={linkedin} width={30} height={30} alt='linkedin logo' /></a>
                                    <a href='https://www.facebook.com/astrapilot/'><img src={facebook} width={30} height={30} alt='facebook logo' /></a>  
                                    </div>
                                    </div>
                            </Grid>
                        </Grid>
                    </div>
                    <Divider variant="middle" sx={{
                        borderColor: "rgb(255, 252, 248)", marginTop: "5.5rem", marginBottom: "2rem", marginLeft: "5%",
                        marginRight: "5%",
                    }} />
                    <div style={{
                        marginLeft: "5%",
                        marginRight: "5%"
                    }}>
                        <Grid container spacing={2}>
                            <Grid xs={2}>
                                <div className='privacyterms'>
                                    <span style={item}>
                                        Privacy
                                    </span>
                                    <NavLink to="/terms">
                                    <span style={item}>
                                        Terms
                                    </span>
                                    </NavLink>
                                </div>
                            </Grid>
                            <Grid xs={10}>
                                <span style={{
                                    fontSize: "18px",
                                    paddingBottom: "3px",
                                    color: "#FFFFFF", float: "right"
                                }}>Copyright © 2023 Astropilot</span>
                            </Grid>
                        </Grid>

                    </div>
                </div>
            ) : (<> <div style={{
                backgroundColor: "#ED7F61",
                height: "32rem",
                width: "100%"
            }}>
                <div style={{ marginRight: "7%", marginLeft: "7%" }}>
                    <div style={column}>
                        <img src={whitelogo} className="whitelogostyle" alt="white logo" />
                        <p style={{
                            color: "white",
                            fontFamily: "avenir",
                            fontSize: "20px",
                            lineHeight: "1.5"
                        }}>Meet the future of early education</p>
                    </div>
                    <div style={{ display: "flex", gap: "3rem", marginBottom: "3rem" }}>
                        <div style={column}>
                            <h3>Product</h3>
                            <HashLink smooth to='/#how-it-works'><span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}> How it works</span></HashLink>
                            <NavLink to='/login'><span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}> Log in</span></NavLink>
                            <NavLink to='/signup'><span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}>Sign up</span></NavLink>
                        </div>

                        <div style={column}>
                            <h3>Company</h3>
                            <HashLink smooth to='/#ourstory'><span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}>Our story</span></HashLink>
                            <a href='https://astrapilot.notion.site/astrapilot/Astrapilot-Careers-dafb51c330ee437a90cd8542a60150a0'><span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}>Careers</span></a>
                            <a href='https://astrapilot.notion.site/astrapilot/Astrapilot-FAQ-e4be762faac2407ea1c524520a4bf3d2'> <span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}>FAQ</span></a>
                        </div>
                    </div>
                    <div className="socials">
                    <a href='https://twitter.com/astrapilot'><img src={twitter} width={30} height={30} alt='twitter logo' /></a>
                    <a href='https://www.instagram.com/astrapilothq/'><img src={instagram} width={30} height={30} alt='instagram logo' /></a>
                    <a href='https://www.tiktok.com/@astrapilot'><img src={tiktok} width={30} height={30} alt='tiktok logo' /></a>
                    <a href='https://www.linkedin.com/company/astrapilot/'><img src={linkedin} width={30} height={30} alt='linkedin logo' /></a>
                    <a href='https://www.facebook.com/astrapilot/'><img src={facebook} width={30} height={30} alt='facebook logo' /></a>
                    </div>

                </div>
                <Divider variant="middle" sx={{
                    borderColor: "rgb(255, 252, 248, 0.7)", marginTop: "2rem", marginBottom: "1.5rem", marginLeft: "5%",
                    marginRight: "5%",
                }} />

                <div style={{ marginRight: "7%", marginLeft: "7%" }}>
                    <div className='privacyterms'>
                        <span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}>
                            Privacy
                        </span>
                        <NavLink to="/terms">
                        <span style={{ fontWeight: "200", color: "rgb(255, 255, 255, 0.7)" }}>
                            Terms
                        </span>
                        </NavLink>
                        <span style={{
                                   fontWeight: "200", color: "rgb(255, 255, 255, 0.7)", marginLeft: "2.5rem",
                                }}>Copyright © 2023 Astropilot</span>
                    </div>
                </div>
            </div></>)}
        </>

    )
}

export default Footer
import React, { useEffect, useState, useCallback, CSSProperties } from "react";
import Spline from "@splinetool/react-spline";
import "./home.css";
import child from "../images/child.png";
import Card from "../app/components/Card";
import rocket from "../images/rocket.png";
import { fontFamily, width } from "@mui/system";

const useMediaQuery = (width: any) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: { matches: any; }) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addEventListener("change", updateTarget)

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeEventListener("change", updateTarget);


  }, []);

  return targetReached;
};


function Home() {


  const body = {
    width: "100%",
    height: "100%",
    justify: "center",
    backgroundColor: "#FFFCF8",
  };

  const myStyle = {
    height: "400px",
    width: "400px",
  };



  const center: CSSProperties = {
    textAlign: "center"
  }



  const card: CSSProperties = {
    position: "absolute",
    top: "10rem",

    zIndex: "10 !important"
  }

  const [position, setPosition] = useState('-40')

  function moveRocket() {
    const speed = 10;
    const scrolltop = window.pageYOffset;
    const scrollAndSpeed = scrolltop / speed - 40;

    setPosition(scrollAndSpeed.toString())
  }

  useEffect(() => {
    window.addEventListener(
      'scroll',
      function () {
        requestAnimationFrame(moveRocket)
      },
      false
    )
  }, [])



  const isBreakpoint = useMediaQuery(700)


  return (
    <div style={body}>
      <div className="backgroundStyle">
        <div className="headers">
          <div className="header">The ultimate adventure for young learners</div>
          <div className="subheader">
            Ignite your child's imagination and love for learning through
            interactive storytelling with their friendly buddy bot.
          </div>
          <a href='/signup'>
            <div className="button">
              <span>Free Trial</span>
              <svg width="34" height="34" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="37" cy="37" r="35.5" stroke="black" strokeWidth="3"></circle>
                <path d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z" fill="black"></path>
              </svg>
            </div>
          </a>
          <div style={{ fontSize: "14px", fontFamily: "avenir", marginLeft: "10px", paddingTop: "8px" }}>No credit card required</div>
        </div>
        <div className="avatarStyle">
          <Spline
            scene="https://prod.spline.design/kftoyzx3WwpZUSfT/scene.splinecode"
            style={myStyle}
          />
        </div>
      </div>
      <div className="starContainer">
      </div>
      <div style={center}>

        <div id="how-it-works" className="section">How It Works</div>


        <div className="howitworks">

          {isBreakpoint ? (<></>) :
            (<img style={{ position: "absolute", top: "13rem", left: `clamp(-50vw, ${position}vw, 100vw)`, zIndex: "10", transform: "rotate(90deg)" }} src={rocket} width={500} alt="image of rocket"></img>)}

          <Card style={card} title="Start Creating" number="1" content="Our friendly buddy bot creates a story with your child personalized to their unique interests." />
          <Card style={card} title="Get Informed" number="2" content="Parents receive ongoing
progress reports to
understand their child's
strengths and growth areas."  />
          <Card style={card} title="Learn & Grow" number="3" content="Buddy bot assesses your child and helps advance their language and social-emotional skills." />

        </div>

        <div id="what-people-say" className="section">What People Are Saying</div>

        <div style={{ marginLeft: "auto", marginRight: "auto", width: "90vw", maxWidth: "80rem", justifyContent: "center", display: "inline-grid", position: "relative" }}>

          <div className="testimonial-box">
            <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/34c65978-05ab-4d27-54e1-4327e9702b00/public" alt="picture of mckenna" width={200} height={200} style={{
              objectFit: "cover",
              borderRadius: "50%", objectPosition: "right top", position: "absolute", bottom: "0", left: "0"
            }} />
            <div className="quote-card-left">
              <div style={{ padding: "10px" }}>

                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" /></svg>
                <div>Astrapilot is like having an extra parent in the room with you! It can be difficult to keep a young child engaged for a long period of time and having Astrapilot really gave us some extra time. Eesa loves stories so when Astrapilot suggested they create a story together he was all in.</div>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ float: "right" }} width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z" /></svg>
                <div className="bottom-cite">
                  <div style={{ marginTop: "1rem", color: "#ED7F61", fontFamily: "avenir-black", fontSize: "1.2rem" }}>Dr. Lailah</div>
                  <div>Medical doctor & parent</div>
                </div>

              </div>
            </div>
          </div>

          <div className="testimonial-box">
            <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/197d4c76-882a-4ea6-00b7-c3f9bac19b00/public" alt="picture of mckenna" width={200} style={{
              objectFit: "cover",
              borderRadius: "50%", objectPosition: "right top", position: "absolute", bottom: "0", right: "0"
            }} />
            <div className="quote-card-right">
              <div style={{ padding: "10px" }}>

                <svg xmlns="http://www.w3.org/2000/svg" style={{ float: "left" }} width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" /></svg>

                <div style={{ marginTop: "2rem" }}>As a teacher, I’m excited by Astrapilot’s potential to give children creative confidence, collaboration skills, and problem solving skills. Imaginative play through Astrapilot facilitates learning and improves brain plasticity.</div>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ float: "right" }} width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z" /></svg>


                <div className="bottom-cite">
                  <div style={{ marginTop: "2rem", color: "#ED7F61", fontFamily: "avenir-black", fontSize: "1.2rem" }}>Hayley</div>
                  <div>Teacher at Kentfield School District</div>
                </div>
              </div>
            </div>
          </div>

          <div className="testimonial-box">
            <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/07d78f34-1cc2-43f7-88fb-8774e71ae400/public" alt="picture of mckenna" width={200} style={{
              objectFit: "cover",
              borderRadius: "50%", objectPosition: "right top", position: "absolute", bottom: "0", left: "0"

            }} />
            <div className="quote-card-left">
              <div style={{ padding: "10px" }}>


                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" /></svg>

                <div>Astrapilot facilitates open-ended, self-directed storytelling, which fosters a sense of agency and creativity, encouraging them to explore their boundless imagination. This experience of imaginative play is of paramount importance for children's cognitive, social, and emotional development.</div>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ float: "right" }} width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z" /></svg>


                <div className="bottom-cite">
                  <div style={{ marginTop: "1rem", color: "#ED7F61", fontFamily: "avenir-black", fontSize: "1.2rem" }}>McKenna</div>
                  <div>Science writer, Columbia University Master of Social Work</div>
                </div>
              </div>
            </div>
          </div>

          <div className="testimonial-box">
            <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/370f3365-320f-479c-8338-6bb1f97f5e00/public" alt="picture of mckenna" width={200} style={{
              objectFit: "cover",
              borderRadius: "50%", objectPosition: "right top", position: "absolute", bottom: "0", right: "0"
            }} />
            <div className="quote-card-right">
              <div style={{ padding: "10px" }}>

                <svg xmlns="http://www.w3.org/2000/svg" style={{ float: "left" }} width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" /></svg>

                <div style={{ marginTop: "2rem" }}>Independent neurodivergent children will love imaginative play with Astrapilot and find it super fun!</div>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ float: "right" }} width="24" height="24" fill="#ED7F61" viewBox="0 0 24 24"><path d="M11 9.275c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275zm13 0c0 5.141-3.892 10.519-10 11.725l-.984-2.126c2.215-.835 4.163-3.742 4.38-5.746-2.491-.392-4.396-2.547-4.396-5.149 0-3.182 2.584-4.979 5.199-4.979 3.015 0 5.801 2.305 5.801 6.275z" /></svg>


                <div className="bottom-cite">
                  <div style={{ marginTop: "2rem", color: "#ED7F61", fontFamily: "avenir-black", fontSize: "1.2rem" }}>Jesse</div>
                  <div>Behavioral therapist for neurodivergent children</div>
                </div>
              </div>
            </div>
          </div>


        </div>

        <div id="features" className="section">Features</div>


        <div className="features">
          <div style={{
            textAlign: "center",
            width: "100%",
            height: "25rem",
            position: "absolute",
            backgroundColor: "rgb(255,252,248)",
            backgroundImage: "radial-gradient(at 50% 40%, rgb(255,255,153, 0.7) 0, transparent 25%), radial-gradient(at 25% 40%, rgb(217, 249, 157, 0.6) 0, transparent 25%), radial-gradient(at 75% 40%, rgb(254, 215, 170, 0.7) 0, transparent 25%)"
          }}>
          </div>
          <div style={{ width: "30rem", zIndex: "10", textAlign: "center", maxWidth: "90vw" }}>
            <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/fe3b8634-351d-42c4-b4eb-4725a40e5100/public" height={120} alt="picture of safety first kids" />
            <div style={{ fontWeight: "600", marginBottom: "10px", marginTop: "10px", fontFamily: "avenir", fontSize: "22px" }}>Safety First</div>
            <p style={{ fontFamily: "avenir", fontSize: "18px" }}>Created by parents for kids, safety is our first priority. Our AI-powered safety checks promptly notify parents or guardians about any language or topics that are not age-appropriate.</p>
          </div>
          <div style={{ width: "30rem", zIndex: "10", textAlign: "center", maxWidth: "90vw" }}>
            <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/1746a8d8-a3ee-4cdc-81d1-975c8c18cf00/public" height={120} alt="picture of safety first kids" />
            <div style={{ fontWeight: "600", marginBottom: "10px", marginTop: "10px", fontFamily: "avenir", fontSize: "22px" }}>Learning Evaluations</div>
            <p style={{ fontFamily: "avenir", fontSize: "18px" }}>Stay informed on your child's progress with post-session summaries and updates on new vocabulary and social-emotional skills your child is acquiring.</p>
          </div>
          <div style={{ width: "30rem", zIndex: "10", textAlign: "center", maxWidth: "90vw" }}>
            <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/23ecac2f-3a33-4abe-f243-e2ec546aac00/public" height={120} alt="picture of safety first kids" />
            <div style={{ fontWeight: "600", marginBottom: "10px", marginTop: "10px", fontFamily: "avenir", fontSize: "22px" }}>Science-Backed Education</div>
            <p style={{ fontFamily: "avenir", fontSize: "18px" }}>Our educational approach and content combine cutting-edge research from top institutions like Stanford and MIT to enhance learning and development. </p>
          </div>

        </div>


        <div id="ourstory" className="section">Our Story</div>

        <img src={child} className="childpic" alt="picture of child" />

        <div style={{ paddingBottom: "6rem" }}>


          <p className="aboutContent">During my pregnancy, I wondered how to provide the best start to education for my child. Early childhood education shapes lifelong outcomes, and achievement gaps open up well before kindergarten. I discovered that early education has become increasingly inaccessible, declining in quality, and exorbitantly expensive. Shockingly, only 50% of children receive formal education before age 5, and just 10% of providers are considered high quality.
          </p>

          <p className="aboutContent">Teaming up with world-class experts in child development, education, and artificial intelligence, together we embarked on a mission to modernize education and use technology to empower every child to reach their full potential.

          </p>
          <p className="aboutContent">We’re building a future where every child, irrespective of their socioeconomic status, can access a personal, affordable, and convenient educator that grows with them, adapting to their unique needs and preferences. Our personalized and affordable approach to learning is accessible anytime and anywhere, allowing us to democratize education and help children around the world succeed and thrive.

          </p>
          <p className="aboutContent">- Hayley, Mom & Founder</p>
        </div>

        <div style={{fontFamily: "avenir-black", fontSize: "20px", color: "#ED7F61", marginBottom: "1rem"}}>FEATURED IN</div>
        <div className="features">
         
        <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/72ac405a-fb00-4321-c108-2ef799dac600/public" height={50} alt="the information logo" />
        <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/26c57be7-0851-40df-2678-b900ac404000/public" height={50} alt="DW logo" />
        <img src="https://imagedelivery.net/Mgig1zCtwXqG3Y6Tm-8KUQ/7123d45f-8fe1-460e-c25b-7f5c78717400/public" height={50} alt="Coveteur logo" />
        </div>
      </div>


    </div>
  );
}

export default Home;

import "./App.css";
import { StrictMode } from "react";

import { ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import "./fonts/avenir.ttf";
import "./fonts/avenir-black.ttf";

import App from "./App";
import theme from "./app/theme";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';


if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StrictMode>
);

import { CHAT_VIEW } from './app/types';
import { config } from './config';


export const configuration = {
  //character: { name: config.INWORLD_CHARACTER },
  character: {name: "workspaces/default-jwznqky-xbxv6r3xb4nzyq/characters/game_master"},
  chatView: CHAT_VIEW.AVATAR,
  //scene: { name: config.INWORLD_SCENE },
  scene: { name: "workspaces/default-jwznqky-xbxv6r3xb4nzyq/characters/game_master"},
  player: { name: 'Participant' },
};

export const DEFAULT_RPM_AVATAR =
  'https://assets.inworld.ai/models/Default.glb';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider
} from '@mui/material';

import { CharacterName } from './CharacterName';
import { ChatView } from './ChatView';
import { PlayerName } from './PlayerName';
import { SceneName } from './SceneName';
import sammy from '../../images/sammy.png';
import '../components/Dashboard.css';

interface ConfigViewProps {
  canStart: boolean;
  onStart: () => Promise<void>;
  onResetForm: () => void;
}

export const ConfigView = (props: ConfigViewProps) => {
  return (
    <>
      {/*
      <Box component="form">
        <Typography variant="h3" component="h1" sx={{ m: 1 }}>
          Chat with buddy bot 
        </Typography>
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <CharacterName />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SceneName />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <PlayerName />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ChatView />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
  */}
      <div className='dashboard-background'>
        <h1>Dashboard</h1>
        <Divider />
        <div className='module'>
          <div className='synopsis'>
            <h3>Storytelling with Sammy</h3>
            <div className='main-content'>
              <img src={sammy} className='character-pic' alt='picture of sammy' />
              <span>Sammy is a childhood educator from San Francisco. She loves working with kids, helping them express their creativity through storytelling. She will encourage your child to create a story together, and patiently listen to them during the journey. </span>
            </div>
            <p style={{fontSize: "20px", paddingTop:"2px", paddingBottom:"2px"}}>Enter child's name to begin</p>
            <div style={{display: "flex"}}>
            <PlayerName />
              <Button
              sx={{borderRadius: "5px", fontSize: "20px"}}
                variant="contained"
                disabled={!props.canStart}
                onClick={props.onStart}
              >
                Start
              </Button>
              </div>
       

            {/*<Button
    sx={{ mr: 2, display: "none"}}
    variant="contained"
    onClick={props.onResetForm}
  >
    Reset
  </Button>
*/}
           
      </div>
        </div>
      </div>
    </>
  );
};

import {
  AdditionalPhonemeInfo,
  Capabilities,
  HistoryItem,
  InworldClient,
  InworldConnectionService,
  InworldPacket,
} from '@inworld/web-sdk';

import { config } from '../config';

interface InworldServiceProps {
  capabilities: Capabilities;
  sceneName: string;
  playerName: string;
  onReady: () => void;
  onPhoneme: (phonemeData: AdditionalPhonemeInfo[]) => void;
  onMessage: (inworldPacket: InworldPacket) => void;
  onHistoryChange: (history: HistoryItem[]) => void;
  onDisconnect: () => void;
}

//added for sending data 
interface Interaction {
  id: string;
  date: string;
  interactionId: string;
  type: string;
  isCharacter: boolean;
  text?: string;
  characterId?: string;
  characterName?: string;
}


const URL =  process.env.REACT_APP_SERVER_URL;

export class InworldService {
  connection: InworldConnectionService;

  constructor(props: InworldServiceProps) {
    const client = new InworldClient()
      .setConfiguration({
        capabilities: props.capabilities,
      })
      .setUser({ fullName: props.playerName })
      .setScene(props.sceneName)
      .setGenerateSessionToken(this.generateSessionToken)
      .setOnError((err) => console.log(err))
      .setOnReady(props.onReady)
      .setOnMessage(props.onMessage)
      .setOnPhoneme(props.onPhoneme)
      .setOnHistoryChange(props.onHistoryChange)
      .setOnDisconnect(props.onDisconnect)
      //printing out very last update
      client.setOnHistoryChange((history: HistoryItem[]) => {
        const text = this.connection?.getTranscript();
        localStorage.setItem("transcript", JSON.stringify(text));
        //this.sendData(history[history.length-1]);
       // console.log(history[history.length-1]);
      });
    this.connection = client.build();
  }



  private async sendData(inworldHistory: any) {
    
    let thisInteraction: Interaction = {
      id: inworldHistory.id,
      date: inworldHistory.date,
      interactionId: inworldHistory.interactionId,
      type: inworldHistory.type,
      isCharacter: inworldHistory.source.isCharacter,
      text: inworldHistory['text'],
      characterId: inworldHistory.source.name
    }

    //console.log(thisInteraction.isCharacter)
    

    const response = await fetch(`${URL}/api/interactions`, {
      method: 'POST',
      body: JSON.stringify(thisInteraction),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const json = await response.json()

    if (!response.ok) {
      console.log(json.error)
    }
    if (response.ok) {
     
      console.log('new workout added:', json)
    
    };

  }

  private async generateSessionToken() {
    const response = await fetch(`${URL}`);

    return response.json();
  }
}
